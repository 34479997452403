var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permission.read_perm == 1)?_c('div',{staticClass:"fluid",attrs:{"id":"subCategoryList"}},[_c('div',[_c('div',{staticClass:"sub-category-list-div"},[_c('v-card',{staticClass:"px-5 py-1"},[_c('v-row',[_c('v-col',{staticClass:"d-flex",staticStyle:{"position":"relative","padding":"0 20px","text-align":"center","margin-top":"20px"},attrs:{"cols":"12"}},[_c('h4',{staticClass:"dmsCategoryTitle2"},[_vm._v(" Sub Kategori Dokumen "),_c('v-tooltip',{attrs:{"bottom":"","color":"grey"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","text":"","icon":"","color":"grey darken-2"},on:{"click":_vm.refresh}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)]}}],null,false,2775313230)},[_c('span',[_vm._v("refresh page")])])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"sub-category-list-toolbar-1"},[_c('v-toolbar',{staticClass:"sub-category-list-toolbar-div",staticStyle:{"height":"60px","border-radius":"10px 10px 0 0"},attrs:{"flat":""}},[_c('v-toolbar-title',{staticStyle:{"color":"indigo"}},[_vm._v(" Sub Kategori Dokumen "),_c('v-tooltip',{attrs:{"bottom":"","color":"grey"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","text":"","icon":"","color":"grey darken-2"},on:{"click":_vm.refresh}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1)]}}],null,false,2775313230)},[_c('span',[_vm._v("refresh page")])])],1),_c('v-divider',{staticClass:"mx-6",attrs:{"inset":"","vertical":""}}),(_vm.isAdmin)?_c('div',{staticStyle:{"margin-right":"20px"}},[(_vm.permission.create_perm == 1)?_c('v-btn',{staticClass:"indigo--text",staticStyle:{"font-size":"12px"},attrs:{"rounded":"","outlined":"","small":"","elevation":"1","color":"indigo"},on:{"click":_vm.addSubCategory}},[_vm._v(" Tambah data ")]):_vm._e()],1):_vm._e()],1)],1),_c('div',{staticClass:"sub-category-list-toolbar-1"},[_c('v-toolbar',{staticClass:"sub-category-list-toolbar-div",staticStyle:{"height":"40px"},attrs:{"flat":""}},[(_vm.permission.update_perm == 1)?_c('div',{staticStyle:{"width":"120px","margin-right":"10px"}},[_c('v-select',{staticStyle:{"position":"relative","top":"15px","font-size":"12px"},attrs:{"disabled":_vm.selectedData.length === 0,"label":"Action","items":[
                      { id: 0, name: '' },
                      { id: 1, name: 'Edit' },
                      { id: 2, name: 'Delete' }
                    ],"item-text":"name","item-value":"id","outlined":"","return-id":"","dense":""},on:{"change":_vm.action},model:{value:(_vm.actionValue),callback:function ($$v) {_vm.actionValue=$$v},expression:"actionValue"}})],1):_vm._e(),_c('v-text-field',{staticStyle:{"position":"relative","top":"15px"},attrs:{"label":"Cari sub kategori","type":"search","append-icon":"mdi-magnify","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEnter($event)}},model:{value:(_vm.paramAPI.keyword),callback:function ($$v) {_vm.$set(_vm.paramAPI, "keyword", $$v)},expression:"paramAPI.keyword"}})],1)],1),_c('div',{staticClass:"sub-category-list-toolbar-2"},[_c('v-expansion-panels',{attrs:{"flat":"","accordion":""}},_vm._l((1),function(item,i){return _c('v-expansion-panel',{key:i,attrs:{"flat":""}},[_c('v-expansion-panel-header',{staticClass:"d-flex flex-column align-items-start",staticStyle:{"padding":"0","margin":"0"}},[_c('v-toolbar',{staticClass:"sub-category-list-toolbar-2",staticStyle:{"height":"60px"},attrs:{"flat":""}},[(_vm.isAdmin)?_c('div',[(_vm.permission.create_perm == 1)?_c('v-btn',{staticClass:"indigo--text",staticStyle:{"font-size":"12px","margin-right":"10px"},attrs:{"rounded":"","outlined":"","small":"","elevation":"1","color":"indigo"},on:{"click":function($event){$event.stopPropagation();return _vm.addSubCategory($event)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-plus")])],1):_vm._e()],1):_vm._e()]),_c('v-toolbar',{staticClass:"sub-category-list-toolbar-2",staticStyle:{"height":"60px","border-radius":"10px 10px 0 0","border-bottom":"1px solid #e0e0e0"},attrs:{"flat":""}},[(_vm.permission.update_perm == 1)?_c('div',{staticStyle:{"width":"120px","margin-right":"10px"}},[_c('v-select',{staticStyle:{"position":"relative","top":"15px","font-size":"12px"},attrs:{"disabled":_vm.selectedData.length === 0,"label":"Action","items":[
                            { id: 0, name: '' },
                            { id: 1, name: 'Edit' },
                            { id: 2, name: 'Delete' }
                          ],"item-text":"name","item-value":"id","outlined":"","return-id":"","dense":""},on:{"click":function($event){$event.stopPropagation();},"change":_vm.action},model:{value:(_vm.actionValue),callback:function ($$v) {_vm.actionValue=$$v},expression:"actionValue"}})],1):_vm._e(),_c('v-text-field',{staticStyle:{"position":"relative","top":"15px"},attrs:{"label":"Cari sub kategori","type":"search","append-icon":"mdi-magnify","outlined":"","dense":""},on:{"click":function($event){$event.stopPropagation();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEnter($event)}},model:{value:(_vm.paramAPI.keyword),callback:function ($$v) {_vm.$set(_vm.paramAPI, "keyword", $$v)},expression:"paramAPI.keyword"}})],1)],1),_c('v-expansion-panel-content',{attrs:{"id":"expansionPanelContent"}})],1)}),1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticStyle:{"min-width":"1200px"},attrs:{"mobile-breakpoint":"0","fixed-header":"","height":"55vh","headers":_vm.headers,"items":_vm.result,"loading":_vm.loading,"show-select":_vm.permission.update_perm == 1,"item-key":"id","options":_vm.options,"footer-props":{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                },"server-items-length":_vm.totalData},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemPerPage},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.created_at))+" ")])]}},{key:"item.updated_at",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.updated_at))+" ")])]}}],null,true),model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}})],1),_c('div',{staticClass:"sub-category-add-new"},[_c('ArchiveAdd',{attrs:{"param":{
                  act: 'add'
                },"permission":_vm.permission},on:{"loadPage":_vm.getDataFromApi}})],1),_c('div',{staticClass:"sub-category-add-new"},[_c('ArchiveAdd',{attrs:{"param":{
                  act: 'update',
                  items: _vm.itemEdit
                },"permission":_vm.permission},on:{"loadPage":_vm.getDataFromApi}})],1)])],1)],1)],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }